import { BaseControl, BaseProps } from '@controls/Base';
import { useCallback } from 'react';
import { useDispatch, useGlobal } from 'reactn';

export default function useControlList() {
    const [controlList] = useGlobal('controls');

    const addControlAction = useDispatch((state, _d, newControl: BaseControl<BaseProps>) => {
        const nextId = state.lastGlobalId + 1;
        const controlWithId = { ...newControl, globalId: nextId };

        return {
            ...state,
            lastGlobalId: nextId,
            controls: {
                ...state.controls,
                [nextId]: controlWithId,
            },
        };
    });
    const removeControl = useDispatch((controls, controlId: number) => {
        const result = { ...controls };
        delete result[controlId];
        return result;
    }, 'controls');
    const addControl = useCallback(
        async (newControl: BaseControl<BaseProps>) => {
            const newState = await addControlAction(newControl);
            return newState.lastGlobalId;
        },
        [addControlAction],
    );
    const getWindow = useCallback(() => Object.values(controlList).find(c => c.type === 'window'), [controlList]);
    const getControlById = useCallback((controlId: number) => controlList[controlId], [controlList]);
    const getControlsByParentId = useCallback(
        (parentControlId: number) => Object.values(controlList).filter(c => c.parentId === parentControlId),
        [controlList],
    );

    return { addControl, removeControl, getWindow, getControlById, getControlsByParentId };
}
