import { useCallback } from 'react';
import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import useSelectedControl from '@hooks/useSelectedControl';
import { PrecisePositionProps } from '@controls/Base';

const Row = tw.div`flex flex-row space-x-2 max-w-full`;
const Field = tw.div`flex flex-col items-start`;
const Label = tw.span`text-sm text-gray-600`;
const Input = tw.input`rounded border border-gray-200 p-1 mt-1 w-20 hover:(border-blue-400) disabled:hover:(border-gray-200 cursor-not-allowed) focus:(border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    name: string;
}

function PrecisePositionProperty({ name }: Props) {
    const { selectedControlId } = useSelectedControl();
    const { control, updateProperties } = useControl(selectedControlId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value: PrecisePositionProps = control.properties[name];
    const handleChange = useCallback(
        (newValue: string) => {
            const newProps = {
                [name]: newValue,
            };
            updateProperties(newProps);
        },
        [name, updateProperties],
    );

    if (!control || !Object.keys(control.properties).includes(name) || !value || value.placement !== 'precise')
        return null;

    return (
        <>
            <Row>
                <Field>
                    <Label>Top</Label>
                    <Input value={value.top} type="number" disabled={control.type === 'window'} />
                </Field>
                <Field>
                    <Label>Left</Label>
                    <Input value={value.left} type="number" disabled={control.type === 'window'} />
                </Field>
            </Row>
            <Row>
                <Field>
                    <Label>Height</Label>
                    <Input value={value.height} type="number" />
                </Field>
                <Field>
                    <Label>Width</Label>
                    <Input value={value.width} type="number" />
                </Field>
            </Row>
        </>
    );
}

export default PrecisePositionProperty;
