import { useCallback, useState } from 'react';
import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import useSelectedControl from '@hooks/useSelectedControl';
import ArtBrowser from '@components/ArtBrowser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const InputContainer = tw.div`rounded border border-gray-200 p-1 mt-1 flex hover:(border-blue-400) focus:(border-blue-400 ring ring-blue-200 ring-opacity-50)`;
const Input = tw.input`focus:outline-none flex-grow`;
const BrowseButton = tw.button`text-gray-600 hover:text-blue-400`;

interface Props {
    name: string;
}

function ArtProperty({ name }: Props) {
    const [isBrowserOpen, setIsBrowserOpen] = useState(false);
    const { selectedControlId } = useSelectedControl();
    const { control, updateProperties } = useControl(selectedControlId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = control.properties[name];
    const handleOpenBrowser = useCallback(() => setIsBrowserOpen(true), []);
    const handleCancel = useCallback(() => setIsBrowserOpen(false), []);
    const handleChange = useCallback(
        (artGid: string) => {
            const newProps = {
                [name]: artGid,
            };
            updateProperties(newProps);
            setIsBrowserOpen(false);
        },
        [name, updateProperties],
    );

    if (!control || !Object.keys(control.properties).includes(name)) return null;

    return (
        <>
            <InputContainer>
                <Input type="text" value={value} onChange={v => handleChange(v.target.value)} />
                <BrowseButton onClick={handleOpenBrowser}>
                    <FontAwesomeIcon icon={solid('folder-open')} />
                </BrowseButton>
            </InputContainer>
            <ArtBrowser isOpen={isBrowserOpen} onSelectAsset={handleChange} onCancel={handleCancel} />
        </>
    );
}

export default ArtProperty;
