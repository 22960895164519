import tw, { styled } from 'twin.macro';
import Header from '@components/Header';
import { useCallback } from 'react';
import useControlList from '@hooks/useControlList';
import ControlPackages from '@controls/index';
import { useGlobal } from 'reactn';
import Persistence from '@services/Persistence';

const Container = tw.div`flex flex-grow flex-col bg-gray-200 max-h-full`;
const Viewport = styled.div(() => [
    tw`flex flex-grow flex-row space-x-2 items-center justify-center`,
    'max-height: calc(100% - 100px);',
]);
const ModalContainer = tw.div`bg-white shadow rounded border border-gray-200 p-4 flex flex-row space-x-4`;
const Section = tw.div`flex flex-col space-y-4 bg-gray-100 w-96 p-4 shadow-lg`;
const SectionTitle = tw.span`text-lg font-bold`;
const Button = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 flex flex-col items-start text-left space-y-4 enabled:hover:(border-blue-400 text-black) enabled:active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50) disabled:(bg-gray-300 cursor-not-allowed)`;
const Title = tw.h2`text-base font-bold`;
const Description = tw.span`leading-relaxed`;
const WindowList = tw.div`flex flex-col space-y-2 overflow-y-scroll h-72 px-2`;
const NoWindows = tw.span`leading-relaxed text-gray-500`;

function NewWindow() {
    const [, loadGlobal] = useGlobal();
    const [, setSaveId] = useGlobal('saveId');
    const { addControl } = useControlList();

    const handleCreatePrecise = useCallback(() => {
        setSaveId(new Date().toLocaleString());
        addControl(ControlPackages.window.factory());
    }, [setSaveId, addControl]);
    const handleLoad = useCallback(
        (saveId: string) => {
            const state = Persistence.Load(saveId);
            loadGlobal(state);
        },
        [loadGlobal],
    );
    const existingWindows = Persistence.List();

    return (
        <Container>
            <Header />
            <Viewport>
                <ModalContainer>
                    <Section>
                        <SectionTitle>Create New Window</SectionTitle>
                        <Button type="button" onClick={handleCreatePrecise}>
                            <Title>New Precise Window</Title>
                            <Description>
                                Creates a new window with precise positioning. Explicit pixel sizes and coordinates are
                                required for every control. Every object on the window, including groups, must also be
                                placed with precise positioning.
                            </Description>
                        </Button>
                        <Button type="button" disabled>
                            <Title>New Relative Window</Title>
                            <Description>
                                Creates a new window with relative positioning. Control size is measured in characters
                                and lines and can grow to fit content. Objects on the window are placed in a relative
                                layout. Groups within the window may use precise or relative positioning.
                            </Description>
                        </Button>
                    </Section>
                    <Section>
                        <SectionTitle>Existing Windows</SectionTitle>
                        {existingWindows.length === 0 && (
                            <NoWindows>You have no saved windows. Why not create one?</NoWindows>
                        )}
                        {existingWindows.length > 0 && (
                            <WindowList>
                                {existingWindows.map(window => (
                                    <Button key={window} onClick={() => handleLoad(window)}>
                                        {window}
                                    </Button>
                                ))}
                            </WindowList>
                        )}
                    </Section>
                </ModalContainer>
            </Viewport>
        </Container>
    );
}

export default NewWindow;
