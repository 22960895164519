import useAgentConnection from '@hooks/useAgentConnection';
import { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = tw.div`flex flex-row space-x-1 items-center`;
const Status = tw.span`text-sm leading-none`;
const Indicator = tw.div`h-4 w-4 rounded-full`;
const Disconnected = styled(Indicator)(() => [tw`bg-red-500`]);
const Connecting = styled(Indicator)(() => [tw`bg-yellow-500`]);
const Connected = styled(Indicator)(() => [tw`bg-green-500`]);

function AgentConnectionIndicator() {
    const { state } = useAgentConnection();
    const statusMessage = useMemo(() => {
        if (state === WebSocket.OPEN) return 'Ready to Play';
        if (state === WebSocket.CONNECTING) return 'Connecting';
        return 'Not Connected';
    }, [state]);

    return (
        <Container>
            {(state === WebSocket.CLOSED || state === WebSocket.CLOSING) && <Disconnected />}
            {state === WebSocket.CONNECTING && <Connecting />}
            {state === WebSocket.OPEN && <Connected />}
            <Status>{statusMessage}</Status>
        </Container>
    );
}

export default AgentConnectionIndicator;
