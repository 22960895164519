import useAgentConnection from '@hooks/useAgentConnection';
import { MouseEvent, useCallback, useMemo } from 'react';
import tw, { styled } from 'twin.macro';

const ModalPresenter = tw.div`bg-black bg-opacity-60 flex items-center justify-center z-10 fixed top-0 left-0 w-screen h-screen`;
const ModalWindow = styled.div(() => [
    tw`flex flex-col space-y-2 bg-white rounded shadow border border-gray-200 p-4 text-black`,
    'min-width: 30rem; max-width: 75%; max-height: 75%;',
]);
const Title = tw.h1`font-bold`;
const Step = tw.span`text-sm`;
const Image = tw.img`max-w-full max-h-full`;
const Actions = tw.div`flex flex-row justify-end space-x-2`;
const Action = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    id: string;
}

function PlayFDOModal({ isOpen, onClose, id }: Props) {
    const { runs } = useAgentConnection();
    const handleMouseSuppress = useCallback((evt: MouseEvent) => {
        evt.stopPropagation();
    }, []);
    const run = useMemo(() => runs[id], [runs, id]);

    const handleClose = useCallback(() => onClose(), [onClose]);

    if (!isOpen || !run) return null;

    return (
        <ModalPresenter onMouseDown={handleMouseSuppress} onMouseUp={handleMouseSuppress}>
            <ModalWindow>
                <Title>Play FDO in Client</Title>
                {Object.keys(run.statuses).length === 0 && <Step>Sending to client...</Step>}
                {Object.keys(run.statuses).map(v => (
                    <Step key={v}>
                        {+v + 1}. {run.statuses[+v]}
                    </Step>
                ))}
                {!run.screenshotUrl && <Step>Waiting for screenshot...</Step>}
                {run.screenshotUrl && <Image alt="" src={`https://artifacts.fdo.tasky.rocks/${run.screenshotUrl}`} />}
                <Actions>
                    <Action onClick={handleClose}>Close</Action>
                </Actions>
            </ModalWindow>
        </ModalPresenter>
    );
}

export default PlayFDOModal;
