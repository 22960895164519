import { bgColor, caption, foreColor, position } from '@controls/CommonProps';
import { FontPropDescriptors } from '@controls/FontProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    position: position(),
    caption: caption(),
    bgColor: bgColor(),
    foreColor: foreColor(),
    ...FontPropDescriptors,
};

export default PropsDescriptor;
