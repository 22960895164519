import { useCallback } from 'react';
import { useGlobal } from 'reactn';

export default function useSelectedControl() {
    const [selectedControlId, setSelectedControlId] = useGlobal('selectedId');
    const selectControl = useCallback((controlId: number) => setSelectedControlId(controlId), [setSelectedControlId]);
    const clearSelected = useCallback(() => setSelectedControlId(-1), [setSelectedControlId]);

    return { selectedControlId, selectControl, clearSelected };
}
