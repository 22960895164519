import tw from 'twin.macro';
import { PropDescriptor } from '@controls/PropDescriptors';
import useControl from '@hooks/useControl';
import useSelectedControl from '@hooks/useSelectedControl';
import BoolProperty from '@components/BoolProperty';
import ColorProperty from '@components/ColorProperty';
import EnumProperty from '@components/EnumProperty';
import NumberProperty from '@components/NumberProperty';
import TextProperty from '@components/TextProperty';
import ArtProperty from './ArtProperty';
import ReferenceImageProperty from './ReferenceImageProperty';
import PrecisePositionProperty from './PrecisePositionProperty';

const Container = tw.div`flex flex-col`;
const Name = tw.span`text-xs font-bold`;

interface Props {
    name: string;
    descriptor: PropDescriptor;
}

function Property({ name, descriptor }: Props) {
    const { selectedControlId } = useSelectedControl();
    const { control } = useControl(selectedControlId);

    if (!selectedControlId || !control) return null;

    return (
        <Container>
            <Name>{descriptor.displayName}</Name>
            {descriptor.inputType === 'text' && <TextProperty name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'color' && <ColorProperty name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'number' && <NumberProperty name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'enum' && <EnumProperty name={name} descriptor={descriptor} />}
            {descriptor.inputType === 'boolean' && <BoolProperty name={name} />}
            {descriptor.inputType === 'art' && <ArtProperty name={name} />}
            {descriptor.inputType === 'reference-image' && <ReferenceImageProperty name={name} />}
            {descriptor.inputType === 'position' && control.properties.position.placement === 'precise' && (
                <PrecisePositionProperty name={name} />
            )}
        </Container>
    );
}

export default Property;
