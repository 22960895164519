import { getArtSize } from '@services/ArtHelper';
import FDORenderer from '@services/FDORenderer';
import Control from './Control';

export default async function renderFDO(control: Control, renderer: FDORenderer) {
    renderer.manStartObject('trigger', control.properties.caption);
    renderer.matRelativeTag(control.globalId);

    if (control.properties.icon) {
        const [width, height] = await getArtSize(control.properties.icon);
        renderer.matArtId(control.properties.icon);
        renderer.matArtHintWidth(width);
        renderer.matArtHintHeight(height);
    }

    if (control.actionFDO) {
        renderer.actReplaceSelectAction(control.actionFDO);
    }

    renderer.manEndObject();
}
