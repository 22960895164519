import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import useSelectedControl from '@hooks/useSelectedControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ChangeEvent, useCallback } from 'react';

const Container = tw.div`flex flex-row space-x-2 mt-2`;
const Button = tw.button`bg-gray-200 w-12 px-4 py-2 rounded text-xs transition-all hover:(ring ring-blue-200 ring-opacity-50 bg-blue-600 text-white) active:(bg-blue-600 ring ring-blue-200 ring-opacity-75)`;
const LoadButton = tw.label`bg-gray-200 w-12 px-4 py-2 rounded text-xs transition-all cursor-pointer hover:(ring ring-blue-200 ring-opacity-50 bg-blue-600 text-white) active:(bg-blue-600 ring ring-blue-200 ring-opacity-75)`;

interface Props {
    name: string;
}

function ReferenceImageProperty({ name }: Props) {
    const { selectedControlId } = useSelectedControl();
    const { control, updateProperties } = useControl(selectedControlId);
    const handleChange = useCallback(
        (referenceImage: string) => {
            const newProps = {
                [name]: referenceImage,
            };
            updateProperties(newProps);
        },
        [name, updateProperties],
    );
    const handlePickReference = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            if (evt.target.files?.length) {
                const reader = new FileReader();
                reader.onload = ev => {
                    handleChange(ev.target?.result as string);
                };
                reader.readAsDataURL(evt.target.files[0]);
            }
        },
        [handleChange],
    );

    if (!control || !Object.keys(control.properties).includes(name)) return null;

    return (
        <Container>
            <LoadButton>
                <FontAwesomeIcon icon={solid('folder-open')} />
                <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handlePickReference} />
            </LoadButton>
            <Button title="Remove reference image" onClick={() => handleChange('')}>
                <FontAwesomeIcon icon={solid('xmark')} />
            </Button>
        </Container>
    );
}

export default ReferenceImageProperty;
