import { MouseEvent, useCallback } from 'react';
import tw from 'twin.macro';
import useControlList from '@hooks/useControlList';
import useSelectedControl from '@hooks/useSelectedControl';
import ControlPresenter from '@components/ControlPresenter';
import DecoratorMenu from './DecoratorMenu';

const Container = tw.div`flex-grow flex items-start justify-start overflow-scroll px-6 pt-8`;

function DesignerSurface() {
    const { getWindow } = useControlList();
    const { selectedControlId, clearSelected } = useSelectedControl();
    const rootWindow = getWindow();
    const handleClick = useCallback(
        (evt: MouseEvent) => {
            if (evt.target === evt.currentTarget) {
                clearSelected();
            }
        },
        [clearSelected],
    );

    return (
        <Container onClick={handleClick}>
            {rootWindow && <ControlPresenter controlId={rootWindow.globalId} />}
            {selectedControlId > 0 && <DecoratorMenu controlId={selectedControlId} />}
        </Container>
    );
}

export default DesignerSurface;
