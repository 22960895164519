import FDORenderer from '@services/FDORenderer';
import Control from './Control';

export default function renderFDO(control: Control, renderer: FDORenderer) {
    renderer.manStartObject('trigger', control.properties.caption);
    renderer.matRelativeTag(control.globalId);

    if (control.properties.position.placement === 'precise') {
        renderer.matPrecisePosition(control.properties.position.left, control.properties.position.top);
        renderer.matPreciseSize(control.properties.position.width, control.properties.position.height);
    }

    if (control.actionFDO) {
        renderer.actReplaceSelectAction(control.actionFDO);
    }

    renderer.manEndObject();
}
