export interface PropGroup {
    title: string;
}

const AppearancePropGroup: PropGroup = {
    title: 'Appearance',
};

const BehaviorPropGroup: PropGroup = {
    title: 'Behavior',
};

const PositionPropGroup: PropGroup = {
    title: 'Position',
};

const MiscellaneousPropGroup: PropGroup = {
    title: 'Miscellaneous',
};

export { AppearancePropGroup, BehaviorPropGroup, PositionPropGroup, MiscellaneousPropGroup };
