/* eslint-disable jsx-a11y/anchor-is-valid */
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAgentConnection from '@hooks/useAgentConnection';
import { MouseEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import tw from 'twin.macro';
import AgentConnectionIndicator from './AgentConnectionIndicator';

const ModalPresenter = tw.div`bg-black bg-opacity-60 flex items-center justify-center z-10 fixed top-0 left-0 w-screen h-screen`;
const ModalWindow = tw.div`w-96 flex flex-col space-y-2 bg-white rounded shadow border border-gray-200 p-4 text-black`;
const Title = tw.h1`font-bold`;
const Description = tw.p`text-sm`;
const Field = tw.div`flex flex-col pt-4 pb-8`;
const InputContainer = tw.form`rounded border border-gray-200 p-1 flex hover:(border-blue-400) focus:(border-blue-400 ring ring-blue-200 ring-opacity-50)`;
const Label = tw.span`text-xs text-gray-500`;
const Input = tw.input`focus:outline-none flex-grow uppercase`;
const ConnectButton = tw.button`text-gray-600 hover:text-blue-400`;
const Actions = tw.div`flex flex-row justify-end space-x-2`;
const Action = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

function ConnectAgentModal({ isOpen, onClose }: Props) {
    const { connect, state } = useAgentConnection();
    const [token, setToken] = useState('');
    const handleMouseSuppress = useCallback((evt: MouseEvent) => {
        evt.stopPropagation();
    }, []);

    const handleConnect = useCallback(
        (e: SyntheticEvent<unknown, unknown>) => {
            e.preventDefault();
            connect(token);
        },
        [connect, token],
    );
    const handleCancel = useCallback(() => onClose(), [onClose]);

    useEffect(() => {
        setToken('');
    }, [isOpen]);

    useEffect(() => {
        if (state === WebSocket.OPEN) onClose();
    }, [state, onClose]);

    if (!isOpen) return null;

    return (
        <ModalPresenter onMouseDown={handleMouseSuppress} onMouseUp={handleMouseSuppress}>
            <ModalWindow>
                <Title>Play FDO in Client</Title>
                <Description>
                    To play FDO in a real client, you must set up the Remote Player agent to act as a proxy to your
                    client&apos;s connection to the Re-AOL server.
                </Description>
                <Description>Once configured, enter the Token below to connect to the remote client.</Description>
                <Description>Need the agent? Contact us on Discord.</Description>
                <Field>
                    <Label>Token</Label>
                    <InputContainer onSubmit={handleConnect}>
                        <Input type="text" maxLength={5} value={token} onChange={e => setToken(e.target.value)} />
                        <ConnectButton type="submit" onClick={handleConnect} disabled={state !== WebSocket.CLOSED}>
                            <FontAwesomeIcon icon={solid('arrow-right')} />
                        </ConnectButton>
                    </InputContainer>
                </Field>
                <AgentConnectionIndicator />
                <Actions>
                    <Action onClick={handleCancel}>Cancel</Action>
                </Actions>
            </ModalWindow>
        </ModalPresenter>
    );
}

export default ConnectAgentModal;
