import { position } from '@controls/CommonProps';
import { FontPropDescriptors } from '@controls/FontProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    position: position(),
    ...FontPropDescriptors,
};

export default PropsDescriptor;
