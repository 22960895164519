import tw, { styled } from 'twin.macro';
import DesignerSurface from '@components/DesignerSurface';
import Header from '@components/Header';
import PropertiesPane from '@components/PropertiesPane';
import ControlTreePane from '@components/ControlTreePane';
import { AgentConnectionProvider } from '@hooks/useAgentConnection';

const Container = tw.div`flex flex-grow flex-col bg-gray-200 max-h-full max-w-full`;
const Viewport = styled.div(() => [tw`flex flex-grow flex-row space-x-2`, 'max-height: calc(100% - 55px);']);

function Designer() {
    return (
        <AgentConnectionProvider>
            <Container>
                <Header showActions />
                <Viewport>
                    <ControlTreePane />
                    <DesignerSurface />
                    <PropertiesPane />
                </Viewport>
            </Container>
        </AgentConnectionProvider>
    );
}

export default Designer;
