import React from 'react';
import ReactDOM from 'react-dom/client';
import { createProvider } from 'reactn';
import { State } from 'reactn/default';
import { GlobalStyles } from 'twin.macro';
import Persistence from '@services/Persistence';
import reportWebVitals from './reportWebVitals';
import Navigator from './Navigator';

const INITIAL_STATE: State = {
    lastGlobalId: 0,
    saveId: '',
    selectedId: -1,
    controls: {},
};
const Provider = createProvider(INITIAL_STATE);
Provider.addCallback(state => {
    if (state.saveId) Persistence.Save(state);
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <GlobalStyles />
        <Provider>
            <Navigator />
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
