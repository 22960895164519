type ManObjectType =
    | 'independent'
    | 'org_group'
    | 'static_multilist'
    | 'static_list'
    | 'dynamic_list'
    | 'trigger'
    | 'ornament'
    | 'view'
    | 'edit_view'
    | 'range'
    | 'select_range'
    | 'tool_group'
    | 'tab_group'
    | 'tab_page';

type MatPosition = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

type MatFontId =
    | 'arial'
    | 'courier'
    | 'times_roman'
    | 'system'
    | 'fixed_system'
    | 'ms_serif'
    | 'ms_sans_serif'
    | 'small_fonts'
    | 'courier_new';

function hexToRGB(color: string) {
    if (/^#([a-f0-9]{3}){1,2}$/.test(color)) {
        let colorHex: string;

        if (color.length === 4) {
            colorHex = `0x${[color[1], color[1], color[2], color[2], color[3], color[3]].join('')}`;
        } else {
            colorHex = `0x${color.substring(1)}`;
        }

        const colorInt = +colorHex;
        // eslint-disable-next-line no-bitwise
        return [Math.max((colorInt >> 16) & 255, 1), Math.max((colorInt >> 8) & 255, 1), Math.max(colorInt & 255, 1)];
    }

    throw new Error('Invalid color');
}

export default class FDORenderer {
    private readonly lines: string[] = [];

    private indentLevel = 0;

    private openObjectCount = 0;

    constructor() {
        this.lines.push(`uni_start_stream <>`);
    }

    public end() {
        this.lines.push('|');
        while (this.openObjectCount > 0) {
            this.manEndObject();
        }

        this.lines.push(`man_update_display <>`);
        this.lines.push(`uni_end_stream <>`);
        return this.lines.join('\r\n');
    }

    public actReplaceSelectAction(rawFDO: string) {
        this.lines.push('|');
        this.writeLine('act_replace_select_action <');
        this.indentLevel += 1;
        this.writeLine('uni_start_stream <>');
        this.indentLevel += 1;
        rawFDO.split('\r\n').forEach(line => this.writeLine(line));
        this.indentLevel -= 1;
        this.writeLine('uni_end_stream <>');
        this.indentLevel -= 1;
        this.writeLine('>');
    }

    public manStartObject(objectType: ManObjectType, title?: string) {
        this.lines.push('|');
        if (title) {
            this.writeLine(`man_start_object <${objectType}, "${title}">`);
        } else {
            this.writeLine(`man_start_object <${objectType}>`);
        }

        this.openObjectCount += 1;
        this.indentLevel += 1;
    }

    public manEndObject() {
        this.indentLevel -= 1;
        this.writeLine(`man_end_object <>`);
        this.openObjectCount -= 1;
    }

    public matRelativeTag = (globalId: number) => this.writeLine(`mat_relative_tag <${globalId}>`);

    public matArtId = (globalId: string) => this.writeLine(`mat_art_id <${globalId}>`);

    public matArtHintWidth = (value: number) => this.writeLine(`mat_art_hint_width <${value}>`);

    public matArtHintHeight = (value: number) => this.writeLine(`mat_art_hint_height <${value}>`);

    public matArtAnimationRate = (value: number) => this.writeLine(`mat_art_animation_rate <${value}>`);

    public matBoolBackgroundFlood = (value: boolean) =>
        this.writeLine(`mat_bool_background_flood <${this.bool(value)}>`);

    public matBoolBackgroundTile = (value: boolean) => this.writeLine(`mat_bool_background_tile <${this.bool(value)}>`);

    public matBoolNonClosable = (value: boolean) => this.writeLine(`mat_bool_non_closeable <${this.bool(value)}>`);

    public matBoolModal = (value: boolean) => this.writeLine(`mat_bool_modal <${this.bool(value)}>`);

    public matBoolResizableV = (value: boolean) => this.writeLine(`mat_bool_resize_vertical <${this.bool(value)}>`);

    public matBoolResizableH = (value: boolean) => this.writeLine(`mat_bool_resize_horizontal <${this.bool(value)}>`);

    public matBoolDefault = (value: boolean) => this.writeLine(`mat_bool_default <${this.bool(value)}>`);

    public matBoolPrecise = (value: boolean) => this.writeLine(`mat_bool_precise <${this.bool(value)}>`);

    public matFontSis = (fontId: MatFontId, size: number, style: number) =>
        this.writeLine(`mat_font_sis <${fontId}, ${size}, ${style}>`);

    public matPosition = (position: MatPosition) => this.writeLine(`mat_position <${position}>`);

    public matPrecisePosition(left: number, top: number) {
        this.writeLine(`mat_precise_x <${left}>`);
        this.writeLine(`mat_precise_y <${top}>`);
    }

    public matPreciseSize(width: number, height: number) {
        this.writeLine(`mat_precise_width <${width}>`);
        this.writeLine(`mat_precise_height <${height}>`);
    }

    public matPreciseWidth(width: number) {
        this.writeLine(`mat_precise_width <${width}>`);
    }

    public matHeight(height: number) {
        this.writeLine(`mat_height <${height}>`);
    }

    public matColorFace(colorHex: string) {
        if (colorHex === 'transparent') return;
        const [r, g, b] = hexToRGB(colorHex);
        this.writeLine(`mat_color_face <${r},${g},${b}>`);
    }

    public matColorText(colorHex: string) {
        if (colorHex === 'transparent') return;
        const [r, g, b] = hexToRGB(colorHex);
        this.writeLine(`mat_color_text <${r},${g},${b}>`);
    }

    public matTriggerStyle = (value: string) => this.writeLine(`mat_trigger_style <${value}>`);

    private writeLine(line: string) {
        const indent = [...Array(this.indentLevel + 1)].join('  ');
        this.lines.push(`${indent}${line}`);
    }

    private bool = (value: boolean) => (value ? 'yes' : 'no');
}
