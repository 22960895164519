import ControlPackages from '@controls/index';
import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import Property from '@components/Property';

const Container = tw.div`flex-grow flex flex-col max-h-full`;
const PaneHeader = tw.div`flex flex-col p-2 bg-gray-600`;
const ControlName = tw.span`text-lg text-gray-200 leading-none`;
const ControlTypeName = tw.span`text-gray-400 text-xs leading-none`;
const PropertyList = tw.div`p-2 flex-grow flex flex-col space-y-4 overflow-y-scroll`;

interface Props {
    controlId: number;
}

function ControlProperties({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!control) return null;

    const controlPackage = ControlPackages[control.type];

    return (
        <Container>
            <PaneHeader>
                <ControlName>{control.name}</ControlName>
                <ControlTypeName>{control.type}</ControlTypeName>
            </PaneHeader>
            <PropertyList>
                {Object.entries(controlPackage.propDescriptors).map(([key, value]) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{!!value && <Property key={key} name={key} descriptor={value} />}</>
                ))}
            </PropertyList>
        </Container>
    );
}

export default ControlProperties;
