import tw, { styled } from 'twin.macro';
import useControl from '@hooks/useControl';
import useSelectedControl from '@hooks/useSelectedControl';
import ControlProperties from '@components/ControlProperties';

interface ContainerProps {
    hasControl?: boolean | undefined;
}

const Container = styled.div(({ hasControl }: ContainerProps) => [
    tw`w-80 shadow max-h-full border-l border-gray-300`,
    hasControl ? tw`bg-white` : tw`bg-gray-100`,
]);

const NoControlMessage = tw.span`p-8 text-xs text-gray-400`;

function PropertiesPane() {
    const { selectedControlId } = useSelectedControl();
    const { control } = useControl(selectedControlId);

    return (
        <Container hasControl={!!control}>
            {!control && <NoControlMessage>Nothing selected.</NoControlMessage>}
            {control && <ControlProperties controlId={selectedControlId} />}
        </Container>
    );
}

export default PropertiesPane;
