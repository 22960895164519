import { ColorPropDescriptor, PositionPropDescriptor, TextPropDescriptor } from './PropDescriptors';
import { AppearancePropGroup, PositionPropGroup } from './PropGroups';

const position = (): PositionPropDescriptor => ({
    inputType: 'position',
    propGroup: PositionPropGroup,
    displayName: 'Object Positioning',
    helpText: 'Describes layout of the object.',
});

const caption = (): TextPropDescriptor => ({
    inputType: 'text',
    propGroup: AppearancePropGroup,
    displayName: 'Caption',
    helpText: 'The title of the object.',
    minLength: 1,
    maxLength: 255,
    isMultiline: false,
});

const bgColor = (): ColorPropDescriptor => ({
    inputType: 'color',
    propGroup: AppearancePropGroup,
    displayName: 'BG Color',
    helpText: 'The background color for the object (RGB).',
    allowTransparent: true,
});

const foreColor = (): ColorPropDescriptor => ({
    inputType: 'color',
    propGroup: AppearancePropGroup,
    displayName: 'Foreground Color',
    helpText: 'The foreground (text, etc) color for the object (RGB).',
    allowTransparent: false,
});

export { position, caption, bgColor, foreColor };
