import { RenderFontFDO } from '@controls/FontProps';
import { getArtSize } from '@services/ArtHelper';
import FDORenderer from '@services/FDORenderer';
import Control from './Control';

export default async function renderFDO(control: Control, renderer: FDORenderer) {
    renderer.manStartObject('trigger', control.properties.caption);
    renderer.matRelativeTag(control.globalId);
    renderer.matBoolDefault(control.properties.isDefault);

    if (control.properties.position.placement === 'precise') {
        renderer.matPrecisePosition(control.properties.position.left, control.properties.position.top);
        renderer.matPreciseSize(control.properties.position.width, control.properties.position.height);
    }

    renderer.matColorFace(control.properties.bgColor);
    renderer.matColorText(control.properties.foreColor);

    RenderFontFDO(control.properties, renderer);

    if (control.properties.triggerStyle && control.properties.triggerStyle !== 'default') {
        renderer.matTriggerStyle(control.properties.triggerStyle);
    }

    if (control.properties.image) {
        const [width, height] = await getArtSize(control.properties.image);
        renderer.matArtId(control.properties.image);
        renderer.matArtHintWidth(width);
        renderer.matArtHintHeight(height);
    }

    if (control.actionFDO) {
        renderer.actReplaceSelectAction(control.actionFDO);
    }

    renderer.manEndObject();
}
