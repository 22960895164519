import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface IRun {
    statuses: Record<number, string>;
    screenshotUrl: string;
}

interface IAgentConnectionContext {
    connect: (token: string) => void;
    executeFDO: (fdo: string) => string | undefined;
    state: number;
    runs: Record<string, IRun>;
}

const AgentConnectionContextInitial: IAgentConnectionContext = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    connect: () => {},
    executeFDO: () => undefined,
    state: 0,
    runs: {},
};

const AgentConnectionContext = createContext(AgentConnectionContextInitial);

interface Props {
    children: React.ReactNode;
}

export function AgentConnectionProvider({ children }: Props) {
    const ws = useRef<WebSocket>();
    const [state, setState] = useState<number>(WebSocket.CLOSED);
    const [runs, setRuns] = useState<Record<string, IRun>>({});

    const connect = useCallback((token: string) => {
        if (ws.current && ws.current.readyState === ws.current.OPEN) {
            ws.current.close();
        }

        ws.current = new WebSocket(`wss://ws.fdo.tasky.rocks/?mode=web&token=${token}`);
        ws.current.onopen = () => setState(WebSocket.OPEN);
        ws.current.onclose = () => setState(WebSocket.CLOSED);
        ws.current.onerror = () => setState(WebSocket.CLOSED);
        ws.current.onmessage = ev => {
            const data = JSON.parse(ev.data);

            if (data.action === 'status') {
                setRuns(r => ({
                    ...r,
                    [data.id]: {
                        ...r[data.id],
                        statuses: {
                            ...r[data.id].statuses,
                            [data.sequence]: data.status,
                        },
                    },
                }));
            }

            if (data.action === 'screenshot') {
                setRuns(r => ({
                    ...r,
                    [data.id]: {
                        ...r[data.id],
                        screenshotUrl: data.key,
                    },
                }));
            }

            console.log(data);
        };
        setState(WebSocket.CONNECTING);
    }, []);

    const executeFDO = useCallback(
        (fdo: string) => {
            if (!ws.current || state !== WebSocket.OPEN) {
                return undefined;
            }
            const id = `${new Date().getTime()}`;
            setRuns(r => ({ ...r, [id]: { statuses: {}, screenshotUrl: '' } }));
            ws.current.send(JSON.stringify({ action: 'play', id, payload: fdo }));

            return id;
        },
        [state],
    );

    const value = useMemo(() => ({ connect, executeFDO, state, runs }), [connect, executeFDO, state, runs]);
    return <AgentConnectionContext.Provider value={value}>{children}</AgentConnectionContext.Provider>;
}

export default function useAgentConnection() {
    const context = useContext(AgentConnectionContext);

    if (context === undefined) {
        throw new Error('useAgentConnection must be used within a AgentConnectionProvider');
    }

    return context;
}
