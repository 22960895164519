import { BaseControl } from '@controls/Base';
import FDORenderer from '@services/FDORenderer';
import Props from './Props';

export default function renderFDO(control: BaseControl<Props>, renderer: FDORenderer) {
    renderer.manStartObject('independent', control.properties.caption);
    renderer.matBoolPrecise(control.properties.position.placement === 'precise');

    if (control.properties.position.placement === 'precise') {
        renderer.matPreciseSize(control.properties.position.width, control.properties.position.height);
        renderer.matBoolResizableH(false);
        renderer.matBoolResizableV(false);
    } else {
        renderer.matBoolResizableH(control.properties.resizableH);
        renderer.matBoolResizableV(control.properties.resizableV);
    }

    renderer.matBoolBackgroundFlood(true);
    renderer.matColorFace(control.properties.bgColor);

    if (control.properties.bgArt) {
        renderer.matArtId(control.properties.bgArt);
        renderer.matBoolBackgroundTile(true);
    }

    renderer.matPosition(control.properties.windowPosition);
    renderer.matBoolNonClosable(!control.properties.closable);
    renderer.matBoolModal(control.properties.modal);
}
