import useControl from '@hooks/useControl';
import { IsLineValid } from '@services/FDOValidator';
import { useEffect, useCallback, useState, MouseEvent } from 'react';

import tw from 'twin.macro';
import FDOEditor from './FDOEditor';

const ModalPresenter = tw.div`bg-black bg-opacity-60 flex items-center justify-center z-10 fixed top-0 left-0 w-screen h-screen`;
const ModalWindow = tw.div`w-2/3 h-1/3 flex flex-col space-y-2 bg-white rounded shadow border border-gray-200 p-4`;
const Title = tw.h1`text-lg`;
const ErrorContainer = tw.div`bg-red-100 border border-red-200 p-2 flex flex-col space-y-2`;
const Actions = tw.div`flex flex-row justify-end space-x-2`;
const Action = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    controlId: number;
    isOpen: boolean;
    onClose: () => void;
}

function ActionFDOEditor({ controlId, isOpen, onClose }: Props) {
    const { getActionFDO, setActionFDO } = useControl(controlId);
    const [error, setError] = useState('');
    const [errorLine, setErrorLine] = useState('');
    const [newValue, setNewValue] = useState('');

    const handleMouseSuppress = useCallback((evt: MouseEvent) => {
        evt.stopPropagation();
    }, []);

    const handleSave = useCallback(() => {
        const lines = newValue.split(/\r\n/);

        for (let lineNumber = 0; lineNumber < lines.length; lineNumber += 1) {
            if (!IsLineValid(lines[lineNumber])) {
                setError(`Error on line ${lineNumber + 1}, token is not recognized:`);
                setErrorLine(lines[lineNumber]);
                return;
            }
        }

        setActionFDO(newValue);
        onClose();
    }, [newValue, onClose, setActionFDO]);

    const handleCancel = useCallback(() => onClose(), [onClose]);

    useEffect(() => {
        if (isOpen) {
            setNewValue(getActionFDO());
        }
    }, [isOpen, getActionFDO]);

    if (!isOpen) return null;

    return (
        <ModalPresenter onMouseDown={handleMouseSuppress} onMouseUp={handleMouseSuppress}>
            <ModalWindow>
                <Title>FDO Editor</Title>
                <FDOEditor onValueChange={value => setNewValue(value)} value={newValue} />
                {!!error && (
                    <ErrorContainer>
                        <span>{error}</span>
                        <pre>{errorLine}</pre>
                    </ErrorContainer>
                )}
                <Actions>
                    <Action onClick={handleCancel}>Cancel</Action>
                    <Action onClick={handleSave}>Save</Action>
                </Actions>
            </ModalWindow>
        </ModalPresenter>
    );
}

export default ActionFDOEditor;
