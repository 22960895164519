import { caption, position } from '@controls/CommonProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import { AppearancePropGroup } from '@controls/PropGroups';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    position: position(),
    caption: caption(),
    icon: {
        inputType: 'art',
        propGroup: AppearancePropGroup,
        displayName: 'Icon Asset',
        helpText: 'If set, the icon for the list item.',
    },
};

export default PropsDescriptor;
