import ControlPackages from '@controls/index';
import { BaseControl, BaseProps } from '@controls/Base';
import { State } from 'reactn/default';

const List = () => {
    const existingListJSON = window.localStorage.getItem('list');
    let existingList: string[];

    if (existingListJSON) {
        existingList = JSON.parse(existingListJSON);
    } else {
        existingList = [];
    }

    return existingList;
};

const Load = (saveId: string) => {
    const existingList = List();
    if (!existingList.includes(saveId)) return undefined;

    const stateJson = window.localStorage.getItem(`window_${saveId}`);

    if (!stateJson) return undefined;

    const state = JSON.parse(stateJson) as State;
    Object.keys(state.controls).forEach(controlId => {
        const control = state.controls[+controlId] as BaseControl<BaseProps>;
        const pkg = ControlPackages[control.type];
        control.properties = pkg.upgradePropsVersion(control.properties);
    });

    return state;
};

const Save = (state: State) => {
    const { saveId } = state;
    const existingList = List();

    if (!existingList.includes(saveId)) {
        existingList.push(saveId);
        window.localStorage.setItem('list', JSON.stringify(existingList));
    }

    window.localStorage.setItem(`window_${saveId}`, JSON.stringify(state));
};

export default { List, Load, Save };
