import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import ImagelistItem from './ImagelistItem';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';
import renderFDO from './RenderFDO';

const createImagelistItem = (parentId?: number | undefined): Control => ({
    globalId: -1,
    parentId,
    name: 'NewImagelistItem',
    type: 'imagelist-item',
    actionFDO: '',
    properties: {
        caption: 'Hello there',
        icon: '',
        position: {
            placement: 'inherited',
        },
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const imagelistItemPackage: ControlPackage<Props, Control> = {
    type: 'imagelist-item',
    icon: solid('angles-right'),
    canHaveChildren: false,
    canHaveAction: true,
    validParentTypes: ['imagelist', 'combo-box'],
    factory: createImagelistItem,
    propDescriptors: PropsDescriptor,
    renderDesigner: controlId => createElement(ImagelistItem, { controlId }),
    renderFDO,
    upgradePropsVersion,
};

export default imagelistItemPackage;
