import { useCallback, useEffect, useRef, useState } from 'react';

export interface Art {
    globalId: string;
    url: string;
}

interface ArtListResponse {
    images: Art[];
    hasMore: boolean;
    token?: string | undefined;
}

function useArtList(prefix: string) {
    const [assets, setAssets] = useState<Art[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const lastPrefix = useRef<string>();
    const continuationToken = useRef<string>();

    const loadMore = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            const baseUrl = 'https://api.fdo.tasky.rocks/art';
            const queryParams: string[] = [];

            if (lastPrefix.current) queryParams.push(`prefix=${encodeURIComponent(lastPrefix.current)}`);
            if (continuationToken.current) queryParams.push(`&token=${encodeURIComponent(continuationToken.current)}`);

            const getQueryString = () => (queryParams.length === 0 ? '' : `?${queryParams.join('&')}`);
            const fetchResult = await fetch(`${baseUrl}${getQueryString()}`);
            const data: ArtListResponse = await fetchResult.json();

            continuationToken.current = data.token;
            setAssets(existing => [...existing, ...data.images]);
            setHasMore(data.hasMore);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (lastPrefix.current !== prefix) {
            lastPrefix.current = prefix;
            continuationToken.current = undefined;
            setAssets([]);

            loadMore();
        }
    }, [prefix, loadMore]);

    return { isLoading, hasMore, assets, loadMore };
}

export default useArtList;
