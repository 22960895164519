import { RenderFontFDO } from '@controls/FontProps';
import FDORenderer from '@services/FDORenderer';
import Control from './Control';

export default function renderFDO(control: Control, renderer: FDORenderer) {
    renderer.manStartObject('ornament', control.properties.caption);
    renderer.matRelativeTag(control.globalId);

    if (control.properties.position.placement === 'precise') {
        renderer.matPrecisePosition(control.properties.position.left, control.properties.position.top);
        renderer.matPreciseSize(control.properties.position.width, control.properties.position.height);
    }

    renderer.matColorFace(control.properties.bgColor);
    renderer.matColorText(control.properties.foreColor);

    RenderFontFDO(control.properties, renderer);

    renderer.manEndObject();
}
