import { caption, position } from '@controls/CommonProps';
import { ControlPropsDescriptor } from '@controls/PropDescriptors';
import Props from './Props';

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    position: position(),
    caption: caption(),
};

export default PropsDescriptor;
