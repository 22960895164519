import tw from 'twin.macro';
import useControlList from '@hooks/useControlList';
import ControlTreeNode from './ControlTreeNode';

const Container = tw.div`w-80 shadow max-h-full bg-white border-r border-gray-300`;
const PaneHeader = tw.p`p-2 bg-gray-600 text-lg text-gray-200 leading-none mb-2`;
const Tree = tw.div`px-2`;

function ControlTreePane() {
    const { getWindow } = useControlList();
    const window = getWindow();

    if (!window) return null;

    return (
        <Container>
            <PaneHeader>Navigator</PaneHeader>
            <Tree>
                <ControlTreeNode indent={0} controlId={window.globalId} />
            </Tree>
        </Container>
    );
}

export default ControlTreePane;
