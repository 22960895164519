import { getArtSize } from '@services/ArtHelper';
import FDORenderer from '@services/FDORenderer';
import Control from './Control';

export default async function renderFDO(control: Control, renderer: FDORenderer) {
    renderer.manStartObject('ornament');
    renderer.matRelativeTag(control.globalId);

    if (control.properties.position.placement === 'precise') {
        renderer.matPrecisePosition(control.properties.position.left, control.properties.position.top);
        renderer.matPreciseSize(control.properties.position.width, control.properties.position.height);
    }

    const [width, height] = await getArtSize(control.properties.asset);
    renderer.matArtId(control.properties.asset);
    renderer.matArtHintWidth(width);
    renderer.matArtHintHeight(height);

    if (control.properties.asset && control.properties.isAnimated && control.properties.animationRate) {
        renderer.matArtAnimationRate(control.properties.animationRate);
    }

    renderer.manEndObject();
}
