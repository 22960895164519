import { BaseControl, BaseProps } from '@controls/Base';
import useControl from '@hooks/useControl';
import { getArtPreviewUrl } from '@services/ArtHelper';
import tw from 'twin.macro';
import Control from './Control';

const Container = tw.div`overflow-hidden select-none flex items-start justify-start`;
const Image = tw.img`select-none`;

interface Props {
    controlId: number;
}

function isPicture(control: BaseControl<BaseProps> | undefined): control is Control {
    return !!control && control.type === 'picture';
}

function Button({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!isPicture(control)) throw new Error('Bad render call');
    let [width, height]: [number | undefined, number | undefined] = [undefined, undefined];
    let background: string | undefined;

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.width;
    }

    if (!control.properties.asset) {
        background = 'rgba(255, 0, 255, 0.5)';
    }

    return (
        <Container style={{ width, height, background }}>
            {control.properties.asset && (
                <Image
                    onDragStart={e => e.preventDefault()}
                    src={getArtPreviewUrl(control.properties.asset)}
                    alt={control.properties.asset}
                />
            )}
        </Container>
    );
}

export default Button;
