import { useCallback, useState } from 'react';
import tw, { styled } from 'twin.macro';
import LogoImage from '@assets/logo.png';
import FDORenderer from '@services/FDORenderer';
import useControlList from '@hooks/useControlList';
import ControlPackages from '@controls/index';
import { BaseControl, BaseProps } from '@controls/Base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AgentConnectionIndicator from '@components/AgentConnectionIndicator';
import useAgentConnection from '@hooks/useAgentConnection';
import ConnectAgentModal from './ConnectAgentModal';
import PlayFDOModal from './PlayFDOModal';

const Container = tw.header`bg-gray-800 text-white shadow p-2 flex flex-row items-center space-x-4`;
const Logo = tw.img`w-10 h-10`;
const Title = tw.h1`font-semibold text-lg flex-grow`;

interface CompileButtonProps {
    showSuccess?: boolean | undefined;
}

const CompileButton = styled.button(({ showSuccess }: CompileButtonProps) => [
    tw`bg-blue-800 w-12 px-4 py-2 rounded text-xs transition-all hover:(ring ring-blue-200 ring-opacity-50) active:(bg-blue-600 ring ring-blue-200 ring-opacity-75)`,
    showSuccess ? tw`bg-green-500! text-white!` : '',
]);

interface Props {
    showActions?: boolean | undefined;
}

function Header({ showActions }: Props) {
    const { getWindow, getControlsByParentId } = useControlList();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showAgentModal, setShowAgentModal] = useState(false);
    const [showPlayModal, setShowPlayModal] = useState(false);
    const [playId, setPlayId] = useState('');
    const { state, executeFDO } = useAgentConnection();

    const getFDO = useCallback(async () => {
        const rootWindow = getWindow();
        if (!rootWindow) return null;

        const renderer = new FDORenderer();

        const renderControl = async (target: BaseControl<BaseProps>) => {
            const children = getControlsByParentId(target.globalId);
            const targetPackage = ControlPackages[target.type];

            await targetPackage.renderFDO(target, renderer, children.length > 0);

            if (children.length > 0) {
                for (let child = 0; child < children.length; child += 1) {
                    // Rendering _must_ be done serially
                    // eslint-disable-next-line no-await-in-loop
                    await renderControl(children[child]);
                }

                renderer.manEndObject();
            }
        };

        await renderControl(rootWindow);

        return renderer.end();
    }, [getWindow, getControlsByParentId]);

    const copyFDO = useCallback(async () => {
        const fdo = await getFDO();
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        if (!fdo) return () => {};

        setShowSuccess(true);
        navigator.clipboard.writeText(fdo.replace(/\r\n\|\r\n/g, '\r\n\r\n'));

        const timer = setTimeout(() => setShowSuccess(false), 2000);
        return () => clearTimeout(timer);
    }, [getFDO]);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const handlePlayFDO = useCallback(async () => {
        if (state !== WebSocket.OPEN) {
            setShowAgentModal(true);
        } else {
            const fdo = await getFDO();
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            if (!fdo) return;

            const id = executeFDO(fdo);
            if (id) {
                setShowPlayModal(true);
                setPlayId(id);
            }
        }
    }, [state, getFDO, executeFDO]);

    const handleCloseAgentModal = useCallback(() => setShowAgentModal(false), []);
    const handleClosePlayModal = useCallback(() => setShowPlayModal(false), []);

    return (
        <Container>
            <Logo src={LogoImage} />
            <Title>ReAOL Designer</Title>
            {showActions && (
                <>
                    <AgentConnectionIndicator />
                    <ConnectAgentModal isOpen={showAgentModal} onClose={handleCloseAgentModal} />
                    <PlayFDOModal id={playId} isOpen={showPlayModal} onClose={handleClosePlayModal} />
                    <CompileButton onClick={handlePlayFDO} title="Play FDO on Remote Client">
                        {state === WebSocket.OPEN && <FontAwesomeIcon icon={solid('play')} />}
                        {state !== WebSocket.OPEN && <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />}
                    </CompileButton>
                    <CompileButton onClick={copyFDO} title="Copy FDO to clipboard" showSuccess={showSuccess}>
                        {showSuccess && <FontAwesomeIcon icon={solid('check')} />}
                        {!showSuccess && <FontAwesomeIcon icon={solid('copy')} />}
                    </CompileButton>
                </>
            )}
        </Container>
    );
}

Header.defaultProps = {
    showActions: false,
};

export default Header;
