import useControlList from '@hooks/useControlList';
import Designer from '@pages/Designer';
import NewWindow from '@pages/NewWindow';

import '@assets/fonts.css';

function Navigator() {
    const { getWindow } = useControlList();
    const rootWindow = getWindow();

    if (rootWindow) {
        return <Designer />;
    }

    return <NewWindow />;
}

export default Navigator;
