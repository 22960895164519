import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import Imagelist from './Imagelist';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';
import renderFDO from './RenderFDO';

const createImagelist = (parentId?: number | undefined): Control => ({
    globalId: -1,
    parentId,
    name: 'NewImagelist',
    type: 'imagelist',
    properties: {
        position: {
            placement: 'precise',
            top: 0,
            left: 0,
            width: 96,
            height: 32,
        },
        fontFace: 'arial',
        fontSize: 12,
        fontBold: false,
        fontItalic: false,
        fontUnderline: false,
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const imagelistPackage: ControlPackage<Props, Control> = {
    type: 'imagelist',
    icon: solid('list'),
    canHaveChildren: true,
    canHaveAction: false,
    validParentTypes: ['window'],
    factory: createImagelist,
    propDescriptors: PropsDescriptor,
    // eslint-disable-next-line react/no-children-prop
    renderDesigner: (controlId, children) => createElement(Imagelist, { controlId, children }),
    renderFDO,
    upgradePropsVersion,
};

export default imagelistPackage;
