import { ActionControl, BaseControl, BaseProps } from '@controls/Base';
import ControlPackages from '@controls/index';
import { useCallback, useMemo } from 'react';
import { useGlobal } from 'reactn';

export default function useControl(controlId: number) {
    const [controlList, setControlList] = useGlobal('controls');
    const control = useMemo<BaseControl<BaseProps> | undefined>(() => controlList[controlId], [controlId, controlList]);

    const updateProperties = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (updatedProps: any) => {
            const existingControl = controlList[controlId];
            const existingProps = { ...existingControl.properties };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const applyUpdate = (partial: any, target: any) => {
                const result = { ...target };

                Object.keys(target).forEach(k => {
                    const partialType = typeof partial[k];

                    if (partialType === 'object') {
                        if (Object.keys(target).includes(k)) {
                            result[k] = applyUpdate(partial[k], target[k]);
                        } else {
                            result[k] = partial[k];
                        }
                    } else if (partialType !== 'undefined') {
                        result[k] = partial[k];
                    }
                });

                return result;
            };

            const existingWithUpdates = applyUpdate(updatedProps, existingProps);

            setControlList({
                ...controlList,
                [controlId]: {
                    ...existingControl,
                    properties: existingWithUpdates,
                },
            });
        },
        [controlList, setControlList, controlId],
    );

    const getActionFDO = useCallback(() => {
        if (!control) return '';
        const controlPackage = ControlPackages[control.type];
        if (!controlPackage.canHaveAction) throw new Error(`${control.type} does not support actions`);

        return (control as unknown as ActionControl).actionFDO;
    }, [control]);

    const setActionFDO = useCallback(
        (value: string) => {
            if (!control) return;

            const controlPackage = ControlPackages[control.type];
            if (!controlPackage.canHaveAction) throw new Error(`${control.type} does not support actions`);

            const newControl: BaseControl<BaseProps> & ActionControl = {
                ...control,
                actionFDO: value,
            };
            setControlList({
                ...controlList,
                [control.globalId]: newControl,
            });
        },
        [control, controlList, setControlList],
    );

    const returnValue = useMemo(
        () => ({ control, getActionFDO, setActionFDO, updateProperties }),
        [control, getActionFDO, setActionFDO, updateProperties],
    );
    return returnValue;
}
