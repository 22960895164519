import ControlPackages from '@controls/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useControl from '@hooks/useControl';
import useControlList from '@hooks/useControlList';
import useSelectedControl from '@hooks/useSelectedControl';
import { useCallback } from 'react';
import tw, { styled } from 'twin.macro';

const Container = tw.div`flex flex-col space-y-1 w-full text-left`;

interface ControlNameProps {
    isSelected: boolean;
}

const ControlName = styled.button(({ isSelected }: ControlNameProps) => [
    tw`text-sm text-left flex flex-row space-x-2 items-center text-gray-600`,
    isSelected ? tw`text-blue-500` : tw`hover:(ring ring-blue-100)`,
]);

interface Props {
    controlId: number;
    indent: number;
}

function ControlTreeNode({ controlId, indent }: Props) {
    const { control } = useControl(controlId);
    const { selectedControlId, selectControl } = useSelectedControl();
    const { getControlsByParentId } = useControlList();
    const children = getControlsByParentId(controlId);
    const handleClick = useCallback(() => selectControl(controlId), [selectControl, controlId]);

    if (!control) return null;
    const controlPackage = ControlPackages[control.type];

    return (
        <Container>
            <ControlName
                onClick={handleClick}
                isSelected={controlId === selectedControlId}
                style={{ paddingLeft: indent * 8 }}
            >
                <FontAwesomeIcon icon={controlPackage.icon} />
                <span>{control.name}</span>
            </ControlName>
            {children.map(c => (
                <ControlTreeNode key={`tree-${c.globalId}`} controlId={c.globalId} indent={indent + 1} />
            ))}
        </Container>
    );
}

export default ControlTreeNode;
