import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import Picture from './Picture';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';
import renderFDO from './RenderFDO';

const createPicture = (parentId?: number | undefined): Control => ({
    globalId: -1,
    parentId,
    name: 'NewPicture',
    type: 'picture',
    properties: {
        asset: '',
        position: {
            placement: 'precise',
            top: 0,
            left: 0,
            width: 100,
            height: 100,
        },
        isAnimated: false,
        animationRate: 150,
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const picturePackage: ControlPackage<Props, Control> = {
    type: 'picture',
    icon: solid('image'),
    canHaveChildren: false,
    canHaveAction: false,
    validParentTypes: ['window'],
    factory: createPicture,
    propDescriptors: PropsDescriptor,
    renderDesigner: controlId => createElement(Picture, { controlId }),
    renderFDO,
    upgradePropsVersion,
};

export default picturePackage;
