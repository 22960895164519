import tw from 'twin.macro';
import useControl from '@hooks/useControl';
import { BaseControl, BaseProps } from '@controls/Base';
import { useEffect, useState } from 'react';
import { getArtPreviewUrl } from '@services/ArtHelper';
import WindowControl from './Control';

const WindowContainer = tw.div`bg-white w-full h-full overflow-hidden`;
const WindowContents = tw.div`relative overflow-hidden`;

interface Props {
    controlId: number;
    children?: React.ReactElement | React.ReactElement[] | undefined;
}

function isWindow(control: BaseControl<BaseProps> | undefined): control is WindowControl {
    return !!control && control.type === 'window';
}

function Window({ controlId, children }: Props) {
    const [isReferenceImage, setIsReferenceImage] = useState(false);
    const { control } = useControl(controlId);
    if (!isWindow(control)) throw new Error('Bad render call');

    let [width, height] = [640, 480];
    let backgroundImage: string | undefined;
    let containerBG: string | undefined;

    useEffect(() => {
        const handleDown = (ev: KeyboardEvent) => {
            if (ev.key === 'q') setIsReferenceImage(true);
        };
        const handleUp = (ev: KeyboardEvent) => {
            if (ev.key === 'q') setIsReferenceImage(false);
        };
        document.addEventListener('keydown', handleDown);
        document.addEventListener('keyup', handleUp);

        return () => {
            document.removeEventListener('keydown', handleDown);
            document.removeEventListener('keyup', handleUp);
        };
    }, []);

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.height;
    }

    if (isReferenceImage && control.properties.referenceImage) {
        containerBG = `url(${control.properties.referenceImage})`;
    }

    if (control.properties.bgArt) {
        backgroundImage = `url(${getArtPreviewUrl(control.properties.bgArt)})`;
    }

    return (
        <WindowContainer style={{ backgroundImage: containerBG }}>
            <WindowContents
                style={{
                    backgroundColor: control.properties.bgColor,
                    backgroundImage,
                    width,
                    height,
                    opacity: isReferenceImage ? 0.25 : 1.0,
                }}
            >
                {!!children && children}
            </WindowContents>
        </WindowContainer>
    );
}

Window.defaultProps = {
    children: undefined,
};

export default Window;
