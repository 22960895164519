import tw from 'twin.macro';

const Container = tw.button`bg-gray-200 p-2 self-center flex flex-col items-center space-y-2 rounded text-gray-600 hover:(bg-gray-100 text-black border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;
const Title = tw.span`text-xs`;

interface Props {
    id: string;
    imageUri: string;
    onSelect: () => void;
}

function ArtBrowserAsset({ id, imageUri, onSelect }: Props) {
    return (
        <Container onClick={onSelect}>
            <img src={imageUri} alt={id} />
            <Title>{id}</Title>
        </Container>
    );
}

export default ArtBrowserAsset;
