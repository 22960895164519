import { BaseControl } from '@controls/Base';
import FDORenderer from '@services/FDORenderer';
import Props from './Props';

export default function renderFDO(control: BaseControl<Props>, renderer: FDORenderer, leaveOpen: boolean) {
    renderer.manStartObject('static_list');
    renderer.matBoolPrecise(true);

    if (!leaveOpen) renderer.manEndObject();
}
