import { ControlPackage } from '@controls/Base';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { createElement } from 'react';
import Control from './Control';
import Radio from './Radio';
import Props from './Props';
import PropsDescriptor from './PropsDescriptor';
import renderFDO from './RenderFDO';

const createRadio = (parentId?: number | undefined): Control => ({
    globalId: -1,
    parentId,
    name: 'NewRadio',
    type: 'radio',
    actionFDO: '',
    properties: {
        caption: 'I am a radio',
        position: {
            placement: 'precise',
            top: 0,
            left: 0,
            width: 96,
            height: 32,
        },
    },
});

const upgradePropsVersion = (props: Props) => ({ ...props });

const radioPackage: ControlPackage<Props, Control> = {
    type: 'radio',
    icon: solid('circle-dot'),
    canHaveChildren: false,
    canHaveAction: true,
    validParentTypes: ['radio-list'],
    factory: createRadio,
    propDescriptors: PropsDescriptor,
    renderDesigner: controlId => createElement(Radio, { controlId }),
    renderFDO,
    upgradePropsVersion,
};

export default radioPackage;
