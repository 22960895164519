import { bgColor, caption, position } from '@controls/CommonProps';
import { ControlPropsDescriptor, EnumPropValue } from '@controls/PropDescriptors';
import {
    AppearancePropGroup,
    BehaviorPropGroup,
    MiscellaneousPropGroup,
    PositionPropGroup,
} from '@controls/PropGroups';
import Props from './Props';

const WindowPositions: EnumPropValue[] = [
    { name: 'Default (Cascaded)', value: '0' },
    { name: 'Top Left', value: '1' },
    { name: 'Top Center', value: '2' },
    { name: 'Top Right', value: '3' },
    { name: 'Middle Left', value: '4' },
    { name: 'Middle Center', value: '5' },
    { name: 'Middle Right', value: '6' },
    { name: 'Bottom Left', value: '7' },
    { name: 'Bottom Center', value: '8' },
    { name: 'Bottom Right', value: '9' },
];

const PropsDescriptor: ControlPropsDescriptor<Props> = {
    position: position(),
    caption: caption(),
    bgColor: bgColor(),
    bgArt: {
        inputType: 'art',
        propGroup: AppearancePropGroup,
        displayName: 'Background Image',
        helpText: 'If selected, sets the background image for the object.',
    },
    windowPosition: {
        inputType: 'enum',
        propGroup: PositionPropGroup,
        displayName: 'Window Position',
        helpText: 'Controls the default location of the window when it is created on the client.',
        options: WindowPositions,
    },
    closable: {
        inputType: 'boolean',
        propGroup: BehaviorPropGroup,
        displayName: 'Closable',
        helpText: 'When disabled, disallows the user from closing the form.',
    },
    modal: {
        inputType: 'boolean',
        propGroup: BehaviorPropGroup,
        displayName: 'Is Modal',
        helpText:
            'When enabled, forces the form to be drawn modally (topmost, cannot interact with other forms in the client).',
    },
    resizableV: {
        inputType: 'boolean',
        propGroup: BehaviorPropGroup,
        displayName: 'Can Resize (Vertical)',
        helpText:
            'When enabled, allows the user to resize the form vertically. Note: for Precision forms, this will be forced to off.',
    },
    resizableH: {
        inputType: 'boolean',
        propGroup: BehaviorPropGroup,
        displayName: 'Can Resize (Horizontal)',
        helpText:
            'When enabled, allows the user to resize the form horizontally. Note: for Precision forms, this will be forced to off.',
    },
    autoCloseOnWindowLimit: {
        inputType: 'boolean',
        propGroup: BehaviorPropGroup,
        displayName: 'Allow AutoClose',
        helpText:
            'When enabled, this form is eligible to be automatically closed by the client when nearing the form limit (23 open windows).',
    },
    referenceImage: {
        inputType: 'reference-image',
        propGroup: MiscellaneousPropGroup,
        displayName: 'Reference Image',
        helpText: 'When set, enables a reference image on the window when ALT is pressed.',
    },
};

export default PropsDescriptor;
