import { BaseControl, BaseProps } from '@controls/Base';
import useControl from '@hooks/useControl';
import { getArtPreviewUrl } from '@services/ArtHelper';
import tw from 'twin.macro';
import ImagelistItemControl from './Control';

const Container = tw.div`flex flex-row`;
const Icon = tw.div`w-4 h-4 mr-2`;
const IconImage = tw.img`max-w-none`;
const Text = tw.span`text-sm`;

interface Props {
    controlId: number;
}

function isValidType(control: BaseControl<BaseProps> | undefined): control is ImagelistItemControl {
    return !!control && control.type === 'imagelist-item';
}

function ImagelistItem({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!isValidType(control)) throw new Error('Bad render call');

    return (
        <Container>
            {control.properties.icon && (
                <Icon>
                    <IconImage
                        onDragStart={e => e.preventDefault()}
                        src={getArtPreviewUrl(control.properties.icon)}
                        alt=""
                    />
                </Icon>
            )}
            <Text>{control.properties.caption}</Text>
        </Container>
    );
}

export default ImagelistItem;
