import { BaseControl, BaseProps } from '@controls/Base';
import { FontFaceToWebMap } from '@controls/FontProps';
import useControl from '@hooks/useControl';
import tw from 'twin.macro';
import Control from './Control';

const Container = tw.div`overflow-hidden select-none flex items-start justify-start`;

interface Props {
    controlId: number;
}

function isLabel(control: BaseControl<BaseProps> | undefined): control is Control {
    return !!control && control.type === 'label';
}

function Button({ controlId }: Props) {
    const { control } = useControl(controlId);
    if (!isLabel(control)) throw new Error('Bad render call');
    let [width, height]: [number | undefined, number | undefined] = [undefined, undefined];

    if (control.properties.position.placement === 'precise') {
        width = control.properties.position.width;
        height = control.properties.position.width;
    }

    const fontFamily = FontFaceToWebMap[control.properties.fontFace];
    const fontSize = `${control.properties.fontSize}pt`;
    const fontWeight = control.properties.fontBold ? 'bold' : undefined;
    const fontStyle = control.properties.fontItalic ? 'italic' : undefined;
    const textDecoration = control.properties.fontUnderline ? 'underline' : undefined;

    return (
        <Container
            style={{
                backgroundColor: control.properties.bgColor,
                width,
                height,
                fontFamily,
                fontSize,
                fontWeight,
                fontStyle,
                textDecoration,
            }}
        >
            <span
                style={{
                    color: control.properties.foreColor,
                    lineHeight: `${control.properties.fontSize}pt`,
                    margin: '6px 2px',
                }}
            >
                {control.properties.caption}
            </span>
        </Container>
    );
}

export default Button;
