const imageSizeCache: Record<string, [number, number]> = {};

const getArtPreviewUrl = (globalId: string) => `https://art.fdo.tasky.rocks/${globalId}.ich0000.gif`;

const getArtSize = (globalId: string): Promise<[number, number]> => {
    return new Promise(resolve => {
        if (imageSizeCache[globalId]) {
            resolve(imageSizeCache[globalId]);
            return;
        }

        const img = new Image();
        img.onload = () => resolve([img.width, img.height]);
        img.src = getArtPreviewUrl(globalId);
    });
};

export { getArtPreviewUrl, getArtSize };
