import useArtList from '@hooks/useArtList';
import { MouseEvent, UIEvent, useCallback, useState } from 'react';
import tw from 'twin.macro';
import ArtBrowserAsset from './ArtBrowserAsset';

const ModalPresenter = tw.div`bg-black bg-opacity-60 flex items-center justify-center z-10 fixed top-0 left-0 w-screen h-screen`;
const ModalWindow = tw.div`w-2/3 h-2/3 flex flex-col space-y-2 bg-white rounded shadow border border-gray-200 p-4`;
const Title = tw.h1`text-lg`;
const AssetList = tw.div`flex flex-row flex-wrap h-full overflow-y-scroll space-x-2 space-y-2`;
const Actions = tw.div`flex flex-row justify-end space-x-2`;
const Action = tw.button`rounded shadow bg-white border border-gray-200 text-xs text-gray-500 p-2 hover:(border-blue-400 text-black) active:(bg-gray-100 border-blue-400 outline-none ring ring-blue-200 ring-opacity-50)`;

interface Props {
    isOpen: boolean;
    onSelectAsset: (globalId: string) => void;
    onCancel: () => void;
}

function ArtBrowser({ isOpen, onSelectAsset, onCancel }: Props) {
    const [prefix, setPrefix] = useState('');
    const { assets, isLoading, hasMore, loadMore } = useArtList(prefix);

    const handleMouseSuppress = useCallback((evt: MouseEvent) => {
        evt.stopPropagation();
    }, []);
    const handleCancel = useCallback(() => onCancel(), [onCancel]);
    const handleScroll = useCallback(
        (evt: UIEvent) => {
            const distanceFromBottom = Math.abs(
                evt.currentTarget.scrollTop + evt.currentTarget.clientHeight - evt.currentTarget.scrollHeight,
            );

            if (!isLoading && hasMore && distanceFromBottom < 100) {
                loadMore();
            }
        },
        [isLoading, hasMore, loadMore],
    );

    if (!isOpen) return null;

    return (
        <ModalPresenter onMouseDown={handleMouseSuppress} onMouseUp={handleMouseSuppress}>
            <ModalWindow>
                <Title>Browse Art Assets</Title>
                <AssetList onScroll={handleScroll}>
                    {assets.map(art => (
                        <ArtBrowserAsset
                            key={art.globalId}
                            id={art.globalId}
                            imageUri={art.url}
                            onSelect={() => onSelectAsset(art.globalId)}
                        />
                    ))}
                </AssetList>
                <Actions>
                    <Action onClick={handleCancel}>Cancel</Action>
                </Actions>
            </ModalWindow>
        </ModalPresenter>
    );
}

export default ArtBrowser;
