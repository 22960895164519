import { BaseControl } from '@controls/Base';
import { RenderFontFDO } from '@controls/FontProps';
import FDORenderer from '@services/FDORenderer';
import Props from './Props';

export default function renderFDO(control: BaseControl<Props>, renderer: FDORenderer, leaveOpen: boolean) {
    renderer.manStartObject('dynamic_list');
    renderer.matBoolPrecise(true);

    if (control.properties.position.placement === 'precise') {
        renderer.matPrecisePosition(control.properties.position.left, control.properties.position.top);
        renderer.matPreciseWidth(control.properties.position.width);
        renderer.matHeight(1);
    }

    RenderFontFDO(control.properties, renderer);

    if (!leaveOpen) renderer.manEndObject();
}
